import Vue from "vue";
import commonFunction from "@/plugins/common";
//引入jquery
import jQuery from "jquery"

Vue.prototype.$ = jQuery

/*
 一些需要基于 vue ,或者 原始 数据 扩展的方法
 */
/*
数组添加删除指定 索引 元素方法
 */
Array.prototype.remove = function (dx) {
    if (isNaN(dx) || dx > this.length) {
        return false;
    }
    for (var i = 0, n = 0; i < this.length; i++) {
        if (this[i] != this[dx]) {
            this[n++] = this[i]
        }
    }
    this.length -= 1
}


Vue.prototype.isPresent = commonFunction.isPresent
Vue.prototype.isBlank = commonFunction.isBlank
Vue.prototype.parseToJson = commonFunction.parseToJson

