/**
 * 判断某个值 是否不为空
 * @param data
 * @returns {boolean} true : 不为空 false:为空
 */
function isPresent(data) {
    if (
        data == null ||
        data === undefined ||
        data === 'null' ||
        data === 'undefined' ||
        data === 'unknown' ||
        data === '{}' ||
        data === '[]' ||
        (typeof data == 'string' && data.trim() == '') ||   //空的字符串
        (data.toString() == '[object Object]' && JSON.stringify(data) == '{}') ||  //空对象
        (Array.isArray(data) && data.length == 0)  //空数组
    ) {
        return false
    } else {
        return true
    }
}

/**
 * 判断某个值 是否为空
 * @param data
 * @returns {boolean} true :为空 false:不为空
 */
function isBlank(data) {
    return isPresent(data) ? false : true
}

/**
 *
 * @param data
 */
function parseToJson(data) {
    let jsonResult = '';
    try {
        let jsonResult = JSON.parse(data)
        return jsonResult
    } catch (e) {
        console.log(e)
    }
    return jsonResult
}


export default {
    isBlank(data) {
        return isBlank(data)
    },
    isPresent(data) {
        return isPresent(data)
    },
    parseToJson(data)
    {
        return parseToJson(data)
    }
}
